let serviceList = [
  {
    title: "Fly up to",
    des: "The height of a 100-storey building",
    img: "assets/img/service-icon/1.png",
  },
  {
    title: "Opening Times",
    des: `All Days from 6:00 Am till 11:00 PM including weekends`,
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Located at",
    des: "At Petra one of the world's seven wonders",
    img: "assets/img/service-icon/3.png",
  },
  {
    title: "Content Marketing",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/service-icon/2.png",
  },
  {
    title: "Mobile App Development",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/service-icon/1.png",
  },

  {
    title: "E-commerce Solutions",
    des: "Lorem is Ipsum is simply is design iomyi is text Lorem Ipsum is simply is our busi Lorem is Ipsum is",
    img: "assets/img/service-icon/3.png",
  },
];

export default serviceList;
