//export const API_URL = "https://petra-balloon.herokuapp.com/api/";
//export const image_url = "http://localhost:5000/static/"
//export const API_URL = "http://localhost:5000/api/";
//export const API_URL = "http://139.59.45.111/api/"

 //export const image_url = "http://139.59.45.111/static/"


 //rendor
//export const API_URL = "https://petra-balloon-backend.onrender.com/api/";
//export const image_url = "https://petra-balloon-backend.onrender.com/static/";

//last one before another userr
//export const API_URL = "https://api.petraballoon.com/api/"
//export const image_url = "https://api.petraballoon.com/static/";


export const API_URL = "https://backend.api.luxotel.com/api/"
export const image_url = "https://backend.api.luxotel.com/static/";